// 登录页
import Vue from 'vue/dist/vue.esm'

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-email-phone-password]',
    data: {
      login_way: window.Ycc.location === "US" ? 'email':'phone',
      intl_tel_input: null
    },
    mounted() {
      this.intl_tel_input = window.initIntlTelInput(this.$refs.intlTelInput)
    },
    methods: {
      formatIntlTelInput() {
        // console.log(e);
        if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
          var currentText = this.intl_tel_input.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') { // sometimes the currentText is an object :)
            console.log(currentText)
            this.intl_tel_input.setNumber(currentText); // will autoformat because of formatOnDisplay=true
            this.phone = this.$refs.intlTelInput.value
          }
        }
      },
    }
  })
})
